import React, { useEffect, useState } from "react";
import { Column, Tabs, Tab, TabList, TabPanels } from "@carbon/react";
import { getActions, getOtb } from "../../services/Otb.service";
import { getLatestPlan } from "../../services/PlanningService";

import * as S from "./Otb.style";

import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import GeneralView from "./GeneralView/GeneralView";
import FinalResults from "./FinalResults/FinalResults";
import BusinessView from "./BusinessView/BusinessView";

export default function Otb() {
  const [index, setIndex] = useState(0);
  const [otbData, setOtbData] = useState([]);
  const [plan, setPlan] = useState({});
  const [actions, setActions] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const getOtbData = async () => {
      const fetchedOtbData = await getOtb(token);
      setOtbData(fetchedOtbData);
    };
    getOtbData();

    const getLatestPlanData = async () => {
      const fetchedPlan = await getLatestPlan(token);
      setPlan(fetchedPlan);
    };
    getLatestPlanData();

    // const getAllActions = async () => {
    //   const fetchedActions = await getActions(token);
    //   setActions(fetchedActions);
    // };
    // getAllActions();
  }, []);

  const definePlanDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const monthsBetween =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());

    return Array.from({ length: monthsBetween + 1 }).map((_, i) => {
      const date = new Date(start.getFullYear(), start.getMonth() + i);
      return date.toISOString().slice(0, 10);
    });
  };

  const planMonths = definePlanDates(plan?.startDate, plan?.endDate);

  const getDateString = (jsDate) => {
    const month = String(jsDate.getMonth() + 1).padStart(2, "0");
    const year = jsDate.getFullYear();
    return `${year}-${month}-01`;
  };

  const today = new Date();
  const todayDateString = getDateString(today);
  const todayIndex = planMonths.indexOf(todayDateString);
  const futureMonths = planMonths.slice(todayIndex);

  function displayDate(dateString) {
    const [year, month, day] = dateString.slice(0, 10).split("-");
    return `${day}/${month}/${year}`;
  }

  return (
    <LayoutWithMenu isCenter={false}>
      <Column lg={16} md={8} sm={4}>
        <S.Section>
          <S.Title>
            <h3>Planejamento</h3>
            {plan.startDate ? (
              <p>
                {displayDate(plan.startDate)} - {displayDate(plan.endDate)}
              </p>
            ) : (
              <></>
            )}
          </S.Title>

          <Tabs
            selectedIndex={index}
            onChange={({ selectedIndex }) => setIndex(selectedIndex)}
          >
            <TabList fullWidth contained>
              <Tab>Visão Geral do Planejamento</Tab>
              <Tab>Visão de Negócio</Tab>
              {/* <Tab>Resultado Geral</Tab> */}
            </TabList>
            <TabPanels>
              <S.TabPanel>
                <GeneralView
                  onNavTab={() => setIndex(1)}
                  otbData={otbData}
                  setOtbData={setOtbData}
                  futureMonths={futureMonths}
                />
              </S.TabPanel>
              <S.TabPanel>
                {otbData.length ? (
                  <BusinessView
                    otbData={otbData}
                    planMonths={planMonths}
                    futureMonths={futureMonths}
                    setOtbData={setOtbData}
                  />
                ) : (
                  <></>
                )}
              </S.TabPanel>
              <S.TabPanel>
                <FinalResults />
              </S.TabPanel>
            </TabPanels>
          </Tabs>
        </S.Section>
      </Column>
    </LayoutWithMenu>
  );
}
