import React, { useEffect, useState } from "react";
import { SelectItem } from "@carbon/react";

import * as S from "./EditPlan.style";

import PlanModal from "../../GeneralView/ItemView/PlanModal/PlanModal";

export default function EditPlan({
  otbData,
  futureMonths,
  planMonths,
  setOtbData,
}) {
  const categories = otbData.map((entry) => entry[0].categoria);

  const [selectedCategory, setSelectedCategory] = useState("");

  const categoryData = otbData.find(
    (category) => category[0].categoria === selectedCategory
  );

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    setSelectedCategory(categories[0]);
  }, [planMonths]);

  return (
    <>
      <S.Container>
        <S.Select
          labelText="Selecione uma categoria"
          value={selectedCategory}
          onChange={handleChange}
        >
          {categories.map((category) => (
            <SelectItem text={category} value={category} />
          ))}
        </S.Select>
      </S.Container>

      {selectedCategory ? (
        <PlanModal
          allMonthsCategoryData={categoryData}
          futureMonths={futureMonths}
          setOtbData={setOtbData}
        />
      ) : (
        <></>
      )}
    </>
  );
}
