import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 82rem) {
    .cds--modal-container--lg {
      inline-size: 90%;
    }
  }

  .cds--modal-content {
    padding-block-end: 0 !important;
  }
`;

export const Section = styled.div`
  border-right: solid #bdbaba 1px;
`;

export const Title = styled.div`
  margin-left: 20px;
`;

export const CategoryTitle = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  color: #5d5d5d;
`;

export const ItemTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 10px 0;
`;

export const ListItem = styled.li`
  margin: 15px 0;
`;

export const TitleSection = styled.div`
  margin: 10px 0 0 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const NumberSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const StatusSection = styled.div`
  display: flex;
  flex-direction: column;

  .titulo-status {
    font-weight: bold;
    color: #999;
    align-self: center;
    margin-bottom: 10px;
  }
`;
