import axios from "./BaseService";

const API_URL =
  `${process.env.REACT_APP_API_URL}` ||
  "http://localhost:3001";

export async function getLatestPlan(token) {
  const planUrl = `${API_URL}/api/planning/latest`;
  const headers = { authorization: token };
  const response = await axios.get(planUrl, { headers });
  return response.data;
}

export async function savePlan(token, plan, leadTime) {
  const planUrl = `${API_URL}/api/planning`;
  const headers = { authorization: token };
  const response = await axios.post(planUrl, { plan, leadTime }, { headers });
  return response.data;
}
