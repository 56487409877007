import React, { useEffect, useState } from "react";
import {
  Header,
  SkipToContent,
  HeaderMenuButton,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalAction,
  HeaderGlobalBar,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  Theme,
} from "@carbon/react";
import { UserAvatar } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import Logo from "../Logo";
import { getLatestPlan } from "../../services/PlanningService";

export default function HeaderUI() {
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);
  const [planRoute, setPlanRoute] = useState("");

  const navigate = useNavigate();

  const onClickSideNavExpand = () => {
    setIsSideNavExpanded(!isSideNavExpanded);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    const resolveRoute = async () => {
      const token = localStorage.getItem("token");
      const plan = await getLatestPlan(token);
      setPlanRoute(plan?.startDate ? "/otb" : "/createPlan");
    };

    resolveRoute();
  }, []);

  return (
    <Theme theme="g100">
      <Header aria-label="Sumply">
        <SkipToContent />
        <HeaderMenuButton
          aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
          aria-expanded={isSideNavExpanded}
        />
        <HeaderName href="/" prefix="">
          <Logo />
        </HeaderName>
        <HeaderNavigation aria-label="Sumply">
          <HeaderMenuItem
            isActive={window.location.pathname === "/home"}
            href="/home"
          >
            Oportunidades
          </HeaderMenuItem>
          <HeaderMenuItem
            isActive={
              window.location.pathname === "/createPlan" ||
              window.location.pathname === "/otb"
            }
            href={planRoute}
          >
            Plano de Vendas
          </HeaderMenuItem>
          <HeaderMenuItem href="#">Mix de Produtos</HeaderMenuItem>
        </HeaderNavigation>
        <HeaderGlobalBar>
          <HeaderGlobalAction
            aria-label="Perfil"
            onClick={() => {
              logout();
            }}
          >
            <UserAvatar size={20} />
          </HeaderGlobalAction>
        </HeaderGlobalBar>
        <SideNav
          aria-label="Side navigation"
          expanded={isSideNavExpanded}
          isPersistent={false}
          onSideNavBlur={onClickSideNavExpand}
        >
          <SideNavItems>
            <HeaderSideNavItems>
              <HeaderMenuItem href="#">Oportunidades</HeaderMenuItem>
              <HeaderMenuItem href="#">Plano de Vendas</HeaderMenuItem>
              <HeaderMenuItem href="#">Mix de Produtos</HeaderMenuItem>
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
      </Header>
    </Theme>
  );
}
