import React, { useState, useRef, useEffect } from "react";
import { Toggle } from "@carbon/react";

import * as S from "./BusinessView.style";

import EditPlan from "./EditPlan/EditPlan";

export default function BusinessView({
  otbData,
  planMonths,
  futureMonths,
  setOtbData,
}) {
  const [showLastYear, setShowLastYear] = useState(false);

  const scrollableDivRef = useRef(null);
  const dataTableRef = useRef(null);

  const categoryData = otbData[0];
  const flatOtbData = otbData.flatMap((category) => category);

  // tem que mostrar 18 + qntdd de meses do planejamento
  const monthsToShowCount = 18 + planMonths.length;

  const lastMonthToShow = planMonths[planMonths.length - 1];

  const allMonthsCategoryData = categoryData;

  const lastMonthToShowIndex =
    allMonthsCategoryData.findIndex((entry) => entry.mes === lastMonthToShow) +
    1;

  const firstMonthToShowIndex = lastMonthToShowIndex - monthsToShowCount;

  const monthsToShow = allMonthsCategoryData.slice(
    firstMonthToShowIndex,
    lastMonthToShowIndex
  );

  const getMonthName = (date) => {
    const [year, month, day] = date.split("T")[0].split("-").map(Number);
    const dateObj = new Date(year, month - 1, day);
    const monthName = dateObj.toLocaleString("pt-BR", { month: "long" });
    const yearSuffix = year.toString().slice(-2);
    return (
      monthName.charAt(0).toUpperCase() + monthName.slice(1) + "/" + yearSuffix
    );
  };

  const getLack = (date) => {
    const monthObjs = flatOtbData.filter((entry) => entry.mes === date);
    const sum = monthObjs.reduce((acc, curr) => {
      if (curr.otbDisponivel > 0) return acc + Number(curr.otbDisponivel);
      else return acc;
    }, 0);
    return sum ? sum : "";
  };

  const getExcess = (date) => {
    const monthObjs = flatOtbData.filter((entry) => entry.mes === date);
    const sum = monthObjs.reduce((acc, curr) => {
      if (curr.otbDisponivel < 0) return acc + Number(curr.otbDisponivel);
      else return acc;
    }, 0);
    return sum ? sum : "";
  };

  const getLastYearDate = (date) => {
    const originalDate = date.split("-");
    const month = String(originalDate[1]).padStart(2, "0");
    return `${originalDate[0] - 1}-${month}-01`;
  };

  const getValue = (date, property, lastYear) => {
    let monthObjs = [];
    if (lastYear) {
      const lastYearDate = getLastYearDate(date.mes);
      monthObjs = flatOtbData.filter((entry) => entry.mes === lastYearDate);
    } else {
      monthObjs = flatOtbData.filter((entry) => entry.mes === date);
    }
    const sum = monthObjs.reduce((acc, curr) => {
      if (curr[property]) return acc + Number(curr[property]);
      else return acc;
    }, 0);
    return sum ? Number(sum) : "";
  };

  const growthPercent = (value, lastYearValue) => {
    if (!value || !lastYearValue) {
      return "";
    }
    const result = (value / lastYearValue - 1) * 100;
    return `${Number(result).toLocaleString("pt-BR", {
      maximumFractionDigits: 1,
    })} %`;
  };

  useEffect(() => {
    const tableWidth = dataTableRef.current.offsetWidth;
    scrollableDivRef.current.scrollTo({ left: tableWidth });
  }, []);

  return (
    <S.OuterContainer>
      <S.Container>
        <h1>Saúde de estoque resumido - Visão empresa</h1>
        <Toggle
          labelA="Comparar ano passado"
          labelB="Comparar ano passado"
          className="toggle"
          size="sm"
          toggled={showLastYear}
          onToggle={() => setShowLastYear(!showLastYear)}
        />

        <S.TablesBox>
          <S.Table paddingBottom="10px">
            <thead>
              <tr>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="otb-cell">Excesso</th>
              </tr>
              <tr>
                <th className="otb-cell">Falta</th>
              </tr>
              <tr>
                <th>Venda em peças</th>
              </tr>
              {showLastYear && (
                <>
                  <tr className="light-green">
                    <th>Venda em peças LY</th>
                  </tr>
                  <tr className="lighter-green">
                    <th>% Cresc. x LY</th>
                  </tr>
                </>
              )}
              <tr>
                <th>Cobertura</th>
              </tr>
              {showLastYear && (
                <tr className="light-green">
                  <th>Cobertura LY</th>
                </tr>
              )}
            </tbody>
          </S.Table>

          <S.BodyTable className="scrollable-div" ref={scrollableDivRef}>
            <S.Table ref={dataTableRef}>
              <thead>
                <tr>
                  {monthsToShow.map((entry) => (
                    <th>{getMonthName(entry.mes)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Falta */}
                <tr>
                  {monthsToShow.map((entry) => (
                    <td>
                      {getLack(entry.mes) > 0 ? (
                        <S.Tag isOver>
                          <div>Falta</div>-{" "}
                          {Math.abs(getLack(entry.mes)).toLocaleString(
                            "pt-br",
                            { maximumFractionDigits: 0 }
                          )}
                        </S.Tag>
                      ) : (
                        ""
                      )}
                    </td>
                  ))}
                </tr>
                {/* Excesso */}
                <tr>
                  {monthsToShow.map((entry) => (
                    <td>
                      {getExcess(entry.mes) < 0 ? (
                        <S.Tag>
                          <div>Excesso</div>{" "}
                          {Math.abs(getExcess(entry.mes)).toLocaleString(
                            "pt-br",
                            { maximumFractionDigits: 0 }
                          )}
                        </S.Tag>
                      ) : (
                        ""
                      )}
                    </td>
                  ))}
                </tr>
                {/* Venda em Peças */}
                <tr>
                  {monthsToShow.map((entry) => (
                    <td className="other-cell">
                      {getValue(entry.mes, "vendaPcs").toLocaleString("pt-br", {
                        maximumFractionDigits: 0,
                      })}
                    </td>
                  ))}
                </tr>
                {/* Venda em peças LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {monthsToShow.map((entry) => (
                      <td>
                        {getValue(entry, "vendaPcs", "lastYear").toLocaleString(
                          "pt-br",
                          { maximumFractionDigits: 0 }
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    {monthsToShow.map((entry) => (
                      <td>
                        {growthPercent(
                          getValue(entry.mes, "vendaPcs"),
                          getValue(entry, "vendaPcs", "lastYear")
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {/* Cobertura */}
                <tr>
                  {monthsToShow.map((entry) => (
                    <td className="other-cell">
                      {getValue(entry.mes, "cobertura").toLocaleString(
                        "pt-br",
                        { maximumFractionDigits: 0 }
                      )}
                    </td>
                  ))}
                </tr>
                {/* Cobertura LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {monthsToShow.map((entry) => (
                      <td>
                        {getValue(
                          entry,
                          "cobertura",
                          "lastYear"
                        ).toLocaleString("pt-br", { maximumFractionDigits: 0 })}
                      </td>
                    ))}
                  </tr>
                )}
              </tbody>
            </S.Table>
          </S.BodyTable>
        </S.TablesBox>
      </S.Container>

      <S.Container>
        <h1>Saúde de estoque - Visão Categoria</h1>
        {planMonths.length ? (
          <EditPlan
            otbData={otbData}
            futureMonths={futureMonths}
            planMonths={planMonths}
            setOtbData={setOtbData}
          />
        ) : (
          <></>
        )}
      </S.Container>
    </S.OuterContainer>
  );
}
