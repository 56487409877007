import React, { useState } from "react";
import { Modal } from "@carbon/react";
import {
  ArrowDown,
  CheckmarkFilled,
  ChevronRight,
  CircleDash,
} from "@carbon/icons-react";

import * as S from "./ItemView.style";

import ButtonDefault from "../../../../components/ButtonDefault/ButtonDefault";
import StockTag from "../StockTag/StockTag";
import PlanModal from "./PlanModal/PlanModal";

export default function ItemView({ categoryData, setOtbData, futureMonths }) {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const status = [
    { titulo: "Análise das Ações", completa: true },
    { titulo: "Ajuste do Plano", completa: false },
    { titulo: "Alinhamento dos Ajustes", completa: false },
  ];

  const planMonthsObjs = categoryData.filter((entry) =>
    futureMonths.includes(entry.mes)
  );

  const otbDisponivel = planMonthsObjs.reduce(
    (acc, curr) => acc + Number(curr.otbDisponivel),
    0
  );

  const potentialLoss = planMonthsObjs.reduce(
    (acc, curr) => acc + Number(curr.perdaPotencial),
    0
  );

  return (
    <>
      <div className="item">
        <S.Section>
          <S.TitleSection>
            <span className="titulo">Categoria</span>
            <span className="nome">{categoryData[0].categoria}</span>
          </S.TitleSection>
        </S.Section>
        <S.Section>
          <StockTag isOver={otbDisponivel < 0} />
          <span className="numero" style={{ marginTop: "5px" }}>
            {Math.abs(otbDisponivel).toLocaleString("pt-br", {
              maximumFractionDigits: 0,
            })}
          </span>
        </S.Section>
        <S.Section>
          <S.NumberSection>
            <span className="titulo">Ações Sugeridas</span>
            <span className="numero">{Number(5).toLocaleString("pt-br")}</span>
          </S.NumberSection>
        </S.Section>
        <S.Section>
          <S.NumberSection>
            <span className="titulo">ROI das Ações</span>
            <span className="numero">
              {potentialLoss.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 0,
              })}
            </span>
          </S.NumberSection>
        </S.Section>
        <div>
          <S.StatusSection>
            <span className="titulo-status">Status</span>
            {status.map((status) => (
              <span className="status">
                <span>{status.titulo}</span>
                {status.completa ? (
                  <CheckmarkFilled color="green" />
                ) : (
                  <CircleDash />
                )}
              </span>
            ))}
          </S.StatusSection>
        </div>
        <button className="openBtn" onClick={() => setOpen(!open)}>
          <ArrowDown />
        </button>
      </div>
      <div className={open ? "painel show" : "painel"}>
        <S.Title>
          <S.CategoryTitle>Categoria</S.CategoryTitle>
          <S.ItemTitle>{categoryData[0].categoria}</S.ItemTitle>
        </S.Title>
        <S.Content>
          <ul>
            <S.ListItem>
              Transferir 4.502 peças de Abril. Sugestão 2.500 peças para Maio;
              2.000 para Junho.
            </S.ListItem>
            <S.ListItem>Incluir compra de 2.750 peças em Maio</S.ListItem>
            <S.ListItem>Incluir compra de 700 peças em Junho</S.ListItem>
            <S.ListItem>Incluir compra de 2.750 peças em Julho</S.ListItem>
            <S.ListItem>Incluir compra de 750 peças em Agosto</S.ListItem>
          </ul>
          <ButtonDefault
            className="ajustarPlano"
            renderIcon={() => <ChevronRight />}
            onClick={() => setOpenModal(true)}
          >
            Ajustar Plano
          </ButtonDefault>
        </S.Content>
      </div>
      <S.Container>
        <Modal
          open={openModal}
          onRequestClose={() => setOpenModal(false)}
          size="lg"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              e.preventDefault();
            }
          }}
          passiveModal
        >
          {futureMonths.length ? (
            <PlanModal
              allMonthsCategoryData={categoryData}
              futureMonths={futureMonths}
              setOtbData={setOtbData}
              showTitle
            />
          ) : (
            <></>
          )}
        </Modal>
      </S.Container>
    </>
  );
}
