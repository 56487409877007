import React, { useContext, useState } from "react";
import { Column, Grid, Checkbox } from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";

import { CreatePlanContext } from "../../../contexts/CreatePlanContext";

import * as S from "./LeadTimeStep.style";

import Icon from "../../../assets/icons/Delivery--truck.svg";
import SkuTable from "./SkuTable/SkuTable";

export default function LeadTimeStep({ onCompleteStep, onBackStep }) {
  const { leadTime, setLeadTime, categories } = useContext(CreatePlanContext);

  const [uniqueLeadTime, setUniqueLeadTime] = useState({
    unique: false,
    value: "",
  });

  const data = categories;

  const getLeadTime = (id) => {
    return leadTime.find((obj) => obj.id === id)?.leadTime;
  };

  const defineLeadTime = (id, categoryName, inputValue) => {
    let newLeadTime = data;

    if (data.find((obj) => obj.id === id)) {
      newLeadTime = leadTime.filter((obj) => obj.id !== id);
    }

    if (inputValue === "" || Number(inputValue > 0)) {
      newLeadTime = [
        ...newLeadTime,
        { id, name: categoryName, leadTime: inputValue },
      ];
      setLeadTime(newLeadTime);
    }
  };

  const areAllComplete = () => {
    const allCategoryIds = data.map((obj) => obj.id);
    return allCategoryIds.every((id) =>
      leadTime.find((obj) => obj.id === id && obj.leadTime)
    );
  };

  const showErrorMessage = () => {
    return !uniqueLeadTime.unique && !areAllComplete() && leadTime.length
      ? true
      : false;
  };

  const disableNextButton = () => {
    if (uniqueLeadTime.unique) {
      return !uniqueLeadTime.value;
    } else {
      return !areAllComplete();
    }
  };

  const handleUniqueInput = (inputValue) => {
    setUniqueLeadTime({
      ...uniqueLeadTime,
      value: inputValue,
    });

    const newLeadTime = data.map((obj) => ({ ...obj, leadTime: inputValue }));
    setLeadTime(newLeadTime);
  };

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="Tempo de entrega" />
          <h4>Tempo de entrega</h4>
        </S.Title>
        <S.Text>
          Agora você vai definir uma média de <strong>lead time</strong> por
          categoria. Pense no tempo em semanas entre fazer o pedido ao
          fornecedor e receber esse pedido em loja. Com esses inputs você poderá
          otimizar seus níveis de estoque, garantindo que seus produtos estejam
          disponíveis quando e onde são necessários. Utilizamos as categorias
          cadastradas para seus itens.
        </S.Text>
      </Column>
      <Column
        lg={8}
        md={4}
        sm={4}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 72px",
        }}
      >
        <S.Text margin="0 0 10px 0">
          Qual a média de semanas que leva desde você encomendar o produto com o
          fornecedor até ele chegar em loja?
        </S.Text>

        {/* <CategoryTable
          getLeadTime={getLeadTime}
          defineLeadTime={defineLeadTime}
          disableInputs={uniqueLeadTime.unique}
        /> */}

        <SkuTable
          getLeadTime={getLeadTime}
          defineLeadTime={defineLeadTime}
          disableInputs={uniqueLeadTime.unique}
        />

        <S.CheckboxWrapper>
          <Checkbox
            id="checkbox"
            labelText="Definir o mesmo tempo de entrega para todas as categorias"
            checked={uniqueLeadTime.unique}
            required
            onChange={(_, { checked }) =>
              setUniqueLeadTime({ ...uniqueLeadTime, unique: checked })
            }
          />
          {uniqueLeadTime.unique && (
            <S.InputWrapper>
              <S.TextInput
                placeholder="Tempo em dias"
                value={uniqueLeadTime.value}
                onChange={(e) => handleUniqueInput(e.target.value)}
                inputMode="numeric"
                pattern="[0-9]*"
              />{" "}
              dias
            </S.InputWrapper>
          )}
        </S.CheckboxWrapper>

        {showErrorMessage() && (
          <S.Text color="red" margin="10px 0 0 0">
            Preencha o lead time de todas as categorias antes de seguir para a
            próxima etapa.
          </S.Text>
        )}

        <S.ButtonSet>
          <S.Button kind="ghost">Editar categorias</S.Button>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(2);
            }}
          >
            <ArrowLeft /> Voltar
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            disabled={disableNextButton()}
            onClick={() => {
              onCompleteStep(2);
            }}
          >
            Próxima
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}
