import React, { useState, useRef, useEffect } from "react";
import { Calculator, Save, Undo, Edit } from "@carbon/icons-react";
import { Toggle, Modal } from "@carbon/react";
import {
  getOtb,
  simulateOtb,
  updateOtb,
} from "../../../../../services/Otb.service";

import * as S from "./PlanModal.style";

export default function PlanModal({
  allMonthsCategoryData,
  futureMonths,
  setOtbData,
  showTitle,
}) {
  const categoria = allMonthsCategoryData[0].categoria;

  // tem que mostrar 18 + qntdd de meses do planejamento
  const monthsToShowCount = 18 + futureMonths.length;

  const lastMonthToShow = futureMonths[futureMonths.length - 1];

  const lastMonthToShowIndex =
    allMonthsCategoryData.findIndex((entry) => entry.mes === lastMonthToShow) +
    1;

  const firstMonthToShowIndex = lastMonthToShowIndex - monthsToShowCount;

  const categoryData = allMonthsCategoryData.slice(
    firstMonthToShowIndex,
    lastMonthToShowIndex
  );

  const initialInputs = categoryData.map((entry) => ({
    id: entry.id,
    categoria: entry.categoria,
    mes: entry.mes,
    shareVenda: entry.shareVenda,
    projecaoRecebimento: entry.projecaoRecebimento,
  }));

  const [showInputs, setShowInputs] = useState(false);
  const [inputs, setInputs] = useState(initialInputs);
  const [showLastYear, setShowLastYear] = useState(false);
  const [adjustOtb, setAdjustOtb] = useState(false);
  const [saveModal, setSaveModal] = useState("");

  const scrollableDivRef = useRef(null);
  const dataTableRef = useRef(null);

  const getMonthName = (date) => {
    const [year, month, day] = date.split("T")[0].split("-").map(Number);
    const dateObj = new Date(year, month - 1, day);
    const monthName = dateObj.toLocaleString("pt-BR", { month: "long" });
    const yearSuffix = year.toString().slice(-2);
    return (
      monthName.charAt(0).toUpperCase() + monthName.slice(1) + "/" + yearSuffix
    );
  };

  const getLastYearDate = (date) => {
    const originalDate = date.split("-");
    const month = String(originalDate[1]).padStart(2, "0");
    return `${originalDate[0] - 1}-${month}-01`;
  };

  const toCurrency = (value, decimals) => {
    return Number(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      maximumFractionDigits: decimals,
    });
  };

  const getShare = (obj) => {
    return obj.shareVenda
      ? Number(obj.shareVenda * 100).toLocaleString("pt-BR", {
          maximumFractionDigits: 1,
        })
      : 0;
  };

  const getVenda = (obj, lastYear) => {
    if (lastYear) {
      const lastYearDate = getLastYearDate(obj.mes);
      const lastYearObj = categoryData.find(
        (entry) => entry.mes === lastYearDate
      );
      return lastYearObj && lastYearObj.vendaRs
        ? toCurrency(lastYearObj.vendaRs, 0)
        : "";
    }

    const venda = obj.vendaRs ? Number(obj.vendaRs) : Number(obj.metaVenda);
    return venda ? toCurrency(venda, 0) : 0;
  };

  const getVendaPcs = (obj, lastYear) => {
    if (lastYear) {
      const lastYearDate = getLastYearDate(obj.mes);
      const lastYearObj = categoryData.find(
        (entry) => entry.mes === lastYearDate
      );
      return lastYearObj && lastYearObj.vendaPcs
        ? Number(lastYearObj.vendaPcs).toLocaleString("pt-BR", {
            maximumFractionDigits: 0,
          })
        : "";
    }

    return obj.vendaPcs
      ? Number(obj.vendaPcs).toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
        })
      : 0;
  };

  const getPrecoMedio = (obj, lastYear) => {
    if (lastYear) {
      const lastYearDate = getLastYearDate(obj.mes);
      const lastYearObj = categoryData.find(
        (entry) => entry.mes === lastYearDate
      );
      return lastYearObj && lastYearObj.precoMedio
        ? toCurrency(lastYearObj.precoMedio, 0)
        : "";
    }
    const precoMedio = obj.precoMedio ? Number(obj.precoMedio) : 0;
    return precoMedio ? toCurrency(precoMedio, 1) : 0;
  };

  const getProjecao = (obj) => {
    return obj.projecaoRecebimento;
  };

  const getEstoqueFinal = (obj, lastYear) => {
    if (lastYear) {
      const lastYearDate = getLastYearDate(obj.mes);
      const lastYearObj = categoryData.find(
        (entry) => entry.mes === lastYearDate
      );
      return lastYearObj && lastYearObj.estoqueFinal
        ? lastYearObj.estoqueFinal
        : "";
    }
    return obj.estoqueFinal ? obj.estoqueFinal : 0;
  };

  const getCobertura = (obj, lastYear) => {
    if (lastYear) {
      const lastYearDate = getLastYearDate(obj.mes);
      const lastYearObj = categoryData.find(
        (entry) => entry.mes === lastYearDate
      );
      return lastYearObj && lastYearObj.cobertura
        ? Number(lastYearObj.cobertura).toLocaleString("pt-BR", {
            maximumFractionDigits: 0,
          })
        : "";
    }
    return obj.cobertura
      ? Number(obj.cobertura).toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
        })
      : 0;
  };

  const getGiro = (obj) => {
    return obj.giro
      ? Number(obj.giro).toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
        })
      : 0;
  };

  const getOtbValue = (obj) => {
    return obj.otbDisponivel
      ? Number(obj.otbDisponivel).toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
        })
      : 0;
  };

  function getOnlyDigits(input) {
    if (!input) {
      return 0;
    }

    // Replace commas with decimals, then remove non-digit characters except the decimal point
    const normalizedInput = input.replace(",", ".").replace(/[^\d.]/g, "");
    return normalizedInput;
  }

  const growthPercent = (value, lastYearValue) => {
    const firstValue = typeof value === "string" ? getOnlyDigits(value) : value;
    const secondValue =
      typeof lastYearValue === "string"
        ? getOnlyDigits(lastYearValue)
        : lastYearValue;

    if (!firstValue || !secondValue) {
      return "";
    }
    const result = (firstValue / secondValue - 1) * 100;

    return `${Number(result).toLocaleString("pt-BR", {
      maximumFractionDigits: 1,
    })} %`;
  };

  const handleInputs = (id, property, value) => {
    const newInputs = [...inputs];
    const propertyInputIndex = inputs.findIndex((input) => input.id === id);

    const finalValue =
      property === "shareVenda" && value !== ""
        ? Number((value / 100).toFixed(4))
        : value;

    if (propertyInputIndex !== -1) {
      newInputs[propertyInputIndex] = {
        ...newInputs[propertyInputIndex],
        [property]: finalValue,
      };
    } else {
      newInputs.push({ id, [property]: finalValue });
    }
    setInputs(newInputs);
  };

  const getInputShareValue = (obj) => {
    const found = inputs.find(
      (input) => input.mes === obj.mes && input.hasOwnProperty("shareVenda")
    );

    return found?.shareVenda || found?.shareVenda === 0
      ? Number((found.shareVenda * 100).toFixed(2))
      : "";
  };

  const getInputProjecaoValue = (obj) => {
    const found = inputs.find(
      (input) =>
        input.mes === obj.mes && input.hasOwnProperty("projecaoRecebimento")
    );
    return found.projecaoRecebimento ? found.projecaoRecebimento : "";
  };

  const getOriginalValues = async () => {
    const token = localStorage.getItem("token");
    const fetchedValues = await getOtb(token);
    setOtbData(fetchedValues);
    setInputs(initialInputs);
    setShowInputs(false);
  };

  const onCalculateClick = () => {
    calculate();
  };

  const calculate = async () => {
    const token = localStorage.getItem("token");
    const simulatedOtb = await simulateOtb(token, inputs);
    setOtbData(simulatedOtb);
    setShowInputs(false);
    setAdjustOtb(true);
  };

  const gatherInputsAndSimulatedData = () => {
    return categoryData.map((entry) => {
      const inputEntry = inputs.find((input) => input.id === entry.id);
      return { ...entry, ...inputEntry };
    });
  };

  const onSaveClick = async () => {
    const token = localStorage.getItem("token");
    console.log(gatherInputsAndSimulatedData());
    const dataToSave = gatherInputsAndSimulatedData();
    const result = await updateOtb(token, dataToSave);

    if (result === "Created") {
      setSaveModal("O planejamento foi ajustado com sucesso!");
    } else {
      setSaveModal(
        "Ocorreu um erro ao ajustar o planejamento. Tente novamente ou contate o administrador do sistema."
      );
    }
  };

  const isFutureMonth = (obj) => {
    return futureMonths.includes(obj.mes);
  };

  useEffect(() => {
    const tableWidth = dataTableRef.current.offsetWidth;
    scrollableDivRef.current.scrollTo({ left: tableWidth });
  }, []);

  return (
    <S.Container>
      <div className="scrollable-div">
        <S.UpperBox>
          <S.ToggleBox negativeMargin={!showTitle}>
            <Toggle
              labelA="Comparar ano passado"
              labelB="Comparar ano passado"
              className="toggle"
              size="sm"
              toggled={showLastYear}
              onToggle={() => setShowLastYear(!showLastYear)}
            />
          </S.ToggleBox>

          {showTitle && (
            <S.Title>
              <p className="subtitle">Categoria</p>
              <p>{categoria}</p>
            </S.Title>
          )}
        </S.UpperBox>

        <S.TablesBox>
          <S.Table paddingBottom="10px">
            <thead>
              <tr>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Estoque ideal para a venda</th>
              </tr>
              <tr>
                <th className="otb-cell">Excesso/Falta de estoque</th>
              </tr>
              <tr className="white start-group">
                <th>Share venda</th>
              </tr>
              <tr>
                <th>Meta de venda</th>
              </tr>
              {showLastYear && (
                <tr className="light-green">
                  <th>Venda R$ LY</th>
                </tr>
              )}
              {showLastYear && (
                <tr className="lighter-green">
                  <th>% Cresc. x LY</th>
                </tr>
              )}
              <tr className="white">
                <th>Venda em peças</th>
              </tr>
              {showLastYear && (
                <tr className="light-green">
                  <th>Venda em peças LY</th>
                </tr>
              )}
              {showLastYear && (
                <tr className="lighter-green">
                  <th>% Cresc. x LY</th>
                </tr>
              )}
              <tr className="white">
                <th>Preço médio</th>
              </tr>
              {showLastYear && (
                <tr className="light-green">
                  <th>Preço médio LY</th>
                </tr>
              )}
              {showLastYear && (
                <tr className="lighter-green">
                  <th>% Cresc. x LY</th>
                </tr>
              )}

              <tr className="start-group">
                <th>Carteira</th>
              </tr>
              <tr className="white">
                <th>Projeção de recebimento</th>
              </tr>
              <tr>
                <th>Estoque final do mês</th>
              </tr>
              {showLastYear && (
                <tr className="light-green">
                  <th>Estoque em peças LY</th>
                </tr>
              )}
              {showLastYear && (
                <tr className="lighter-green">
                  <th>% Cresc. x LY</th>
                </tr>
              )}

              <tr className="start-group">
                <th>Cobertura</th>
              </tr>
              {showLastYear && (
                <tr className="light-green">
                  <th>Cobertura LY</th>
                </tr>
              )}
              <tr className="white">
                <th>Giro</th>
              </tr>
            </tbody>
          </S.Table>

          <S.BodyTable className="scrollable-div" ref={scrollableDivRef}>
            <S.Table ref={dataTableRef}>
              <thead>
                <tr>
                  {categoryData.map((d) => (
                    <th>{getMonthName(d.mes)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* Estoque ideal */}
                <tr>
                  {categoryData.map((d) => (
                    <td>{d.estoqueIdeal ? d.estoqueIdeal : 0}</td>
                  ))}
                </tr>
                {/* Excesso/falta */}
                <tr>
                  {categoryData.map((d) => (
                    <td>
                      {getOtbValue(d) > 0 && (
                        <S.Tag isOver>
                          <div>Falta</div>- {Math.abs(getOtbValue(d))}
                        </S.Tag>
                      )}
                      {getOtbValue(d) < 0 && (
                        <S.Tag>
                          <div>Excesso</div>
                          {Math.abs(getOtbValue(d))}
                        </S.Tag>
                      )}
                    </td>
                  ))}
                </tr>
                {/* ShareVenda */}
                <tr className="white start-group">
                  {inputs.map((d) => (
                    <td>
                      {showInputs && isFutureMonth(d) ? (
                        <S.Input
                          type="number"
                          onChange={(e) =>
                            handleInputs(d.id, "shareVenda", e.target.value)
                          }
                          value={getInputShareValue(d)}
                        />
                      ) : (
                        getShare(d)
                      )}
                      {`${" %"}`}
                    </td>
                  ))}
                </tr>

                {/* Meta de venda */}
                <tr>
                  {categoryData.map((d) => (
                    <td>{getVenda(d)}</td>
                  ))}
                </tr>
                {/* Venda R$ LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {categoryData.map((d) => (
                      <td>{getVenda(d, "lastYear")}</td>
                    ))}
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    {categoryData.map((d) => (
                      <td>
                        {growthPercent(getVenda(d), getVenda(d, "lastYear"))}
                      </td>
                    ))}
                  </tr>
                )}
                {/* Venda peças */}
                <tr className="white">
                  {categoryData.map((d) => (
                    <td>{getVendaPcs(d)}</td>
                  ))}
                </tr>
                {/* Venda em peças LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {categoryData.map((d) => (
                      <td>{getVendaPcs(d, "lastYear")}</td>
                    ))}
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    {categoryData.map((d) => (
                      <td>
                        {growthPercent(
                          getVendaPcs(d),
                          getVendaPcs(d, "lastYear")
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {/* Preço Medio */}
                <tr className="white">
                  {categoryData.map((d) => (
                    <td>{getPrecoMedio(d)}</td>
                  ))}
                </tr>
                {/* Preço Medio LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {categoryData.map((d) => (
                      <td className="light-green">
                        {getPrecoMedio(d, "lastYear")}
                      </td>
                    ))}
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    {categoryData.map((d) => (
                      <td>
                        {growthPercent(
                          getPrecoMedio(d),
                          getPrecoMedio(d, "lastYear")
                        )}
                      </td>
                    ))}
                  </tr>
                )}

                {/* Carteira */}
                <tr className="start-group">
                  {categoryData.map((d) => (
                    <td>{d.carteira ? d.carteira : "0"}</td>
                  ))}
                </tr>
                {/* Projeção recebimento */}
                <tr className="white">
                  {inputs.map((d) => (
                    <td>
                      {showInputs && isFutureMonth(d) ? (
                        <S.Input
                          type="number"
                          onChange={(e) =>
                            handleInputs(
                              d.id,
                              "projecaoRecebimento",
                              e.target.value
                            )
                          }
                          value={getInputProjecaoValue(d)}
                        />
                      ) : (
                        getProjecao(d)
                      )}
                    </td>
                  ))}
                </tr>
                {/* Estoque final */}
                <tr>
                  {categoryData.map((d) => (
                    <td>
                      {Number(getEstoqueFinal(d)).toLocaleString("pt-BR", {
                        maximumFractionDigits: 0,
                      })}
                    </td>
                  ))}
                </tr>
                {/* Estoque em peças LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {categoryData.map((d) => (
                      <td>
                        {Number(getEstoqueFinal(d, "lastYear")).toLocaleString(
                          "pt-BR",
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </td>
                    ))}
                  </tr>
                )}
                {showLastYear && (
                  <tr className="lighter-green">
                    {categoryData.map((d) => (
                      <td>
                        {growthPercent(
                          getEstoqueFinal(d),
                          getEstoqueFinal(d, "lastYear")
                        )}
                      </td>
                    ))}
                  </tr>
                )}

                {/* Cobertura */}
                <tr className="start-group">
                  {categoryData.map((d) => (
                    <td>{getCobertura(d)}</td>
                  ))}
                </tr>
                {/* Cobertura LY */}
                {showLastYear && (
                  <tr className="light-green">
                    {categoryData.map((d) => (
                      <td>{getCobertura(d, "lastYear")}</td>
                    ))}
                  </tr>
                )}
                {/* Giro */}
                <tr className="white">
                  {categoryData.map((d) => (
                    <td>{getGiro(d)}</td>
                  ))}
                </tr>
              </tbody>
            </S.Table>
          </S.BodyTable>
        </S.TablesBox>

        <S.ButtonGroup>
          {!showInputs && (
            <S.Button
              green
              renderIcon={Edit}
              onClick={() => setShowInputs(!showInputs)}
            >
              Simular
            </S.Button>
          )}
          {!showInputs && adjustOtb ? (
            <S.Button
              green
              renderIcon={Save}
              tabIndex={-1}
              disabled={!adjustOtb}
              onClick={onSaveClick}
            >
              Salvar valores
            </S.Button>
          ) : (
            <></>
          )}
          {showInputs && (
            <>
              <S.Button renderIcon={Undo} onClick={getOriginalValues} green>
                Retornar valores originais
              </S.Button>
              <S.Button
                green
                renderIcon={Calculator}
                onClick={onCalculateClick}
                disabled={!showInputs}
              >
                Calcular
              </S.Button>
            </>
          )}
        </S.ButtonGroup>
      </div>

      {saveModal && (
        <Modal
          open={saveModal}
          onRequestClose={() => setSaveModal("")}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              e.preventDefault();
            }
          }}
          size="sm"
          passiveModal
        >
          <S.SaveModalContainer>
            <p>{saveModal}</p>
          </S.SaveModalContainer>
        </Modal>
      )}
    </S.Container>
  );
}
