import React from "react";

import * as S from "./IndexesSection.style";

import StockTag from "../StockTag/StockTag";

export default function IndexesSection({ otbData, futureMonths }) {
  const allCategoriesObjs = otbData.flat();

  const futureMonthsObjs = allCategoriesObjs.filter((obj) =>
    futureMonths.includes(obj.mes)
  );

  const stockLack = futureMonthsObjs.reduce((acc, curr) => {
    if (curr.otbDisponivel > 0) {
      return acc + curr.otbDisponivel;
    } else {
      return acc;
    }
  }, 0);

  const overStock = futureMonthsObjs.reduce((acc, curr) => {
    if (curr.otbDisponivel < 0) {
      return acc + curr.otbDisponivel;
    } else {
      return acc;
    }
  }, 0);

  const potentialLoss = futureMonthsObjs.reduce(
    (acc, curr) => acc + Number(curr.perdaPotencial),
    0
  );

  return (
    <S.Container>
      <div>
        <S.Title>Situação atual do estoque</S.Title>
        <S.StockSection>
          <S.Box twoRows>
            <StockTag />
            <S.Number>
              -{" "}
              {stockLack.toLocaleString("pt-BR", { maximumFractionDigits: 0 })}
            </S.Number>
          </S.Box>
          <S.Box twoRows>
            <StockTag isOver />
            <S.Number>
              {Math.abs(overStock).toLocaleString("pt-br", {
                maximumFractionDigits: 0,
              })}
            </S.Number>
          </S.Box>
        </S.StockSection>
      </div>

      <S.RightContainer>
        <div>
          <S.Title>Total de ações em aberto</S.Title>
          <S.Box>
            <S.Number>234</S.Number>
          </S.Box>
        </div>

        <div>
          <S.Title>ROI total em ações</S.Title>
          <S.Box>
            <S.Number>
              {potentialLoss.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 0,
              })}
            </S.Number>
          </S.Box>
        </div>

        <div>
          <S.Title>Status Geral</S.Title>
          <S.Box>
            <S.Number>12% / 100%</S.Number>
          </S.Box>
        </div>
      </S.RightContainer>
    </S.Container>
  );
}
