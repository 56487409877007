import styled from "styled-components";
import { Select as CSelect } from "@carbon/react";

export const Container = styled.div`
  display: flex;
  justify-content: center;

  .cds--label {
    align-self: center;
    margin-top: 10px;
  }

  .cds--select-input {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const Select = styled(CSelect)`
  max-width: 250px;
`;
