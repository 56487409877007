import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > .info {
  }
  > .lista {
    > .item {
      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      padding: 5px;
      font-size: 14px;
      color: #434343;
      margin-bottom: 15px;
      > div {
        display: flex;
        flex-direction: column;
        > span:first-child {
          margin-bottom: 5px;
        }
        &:first-child {
          height: 80px;
        }
        &:nth-child(2) {
          margin-left: 8px;
          align-items: center;
        }
        .photo {
          width: auto;
        }
        .titulo {
          font-weight: bold;
          color: #999;
        }
        .nome {
          font-size: 28px;
          font-weight: bold;
        }
        .pill {
          width: fit-content;
          border-radius: 500px;
          padding: 4px 8px;
          color: white;
          font-weight: bold;
          &.red {
            background-color: red;
          }
          &.green {
            background-color: green;
          }
        }
        .numero {
          font-size: 20px;
          font-weight: bold;
        }
        .status {
          display: flex;
          align-items: normal;
          justify-content: right;
          width: 100%;
          > span {
            margin-right: 5px;
          }
        }
      }
      .openBtn {
        position: absolute;
        border-radius: 500px;
        border: none;
        left: 50%;
        margin-bottom: -80px;
        color: white;
        background-color: green;
        width: 30px;
        height: 30px;
        padding-top: 2px;
        cursor: pointer;
        &:hover {
          background-color: olive;
        }
      }
    }
    > .painel {
      display: none;
      text-align: left;
      border: 1px solid #bdbaba;
      padding: 8px;
      margin: -7px 0 10px 0;
      font-size: 14px;
      &.show {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
      }
      ul {
        list-style: disc;
        margin-left: 20px;
      }
      .ajustarPlano {
        background-color: #48fa7f;
        width: 200px;
        align-self: self-end;
        color: black;
        &:hover {
          background-color: #41a05f;
          color: white;
        }
      }
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  margin: 0 100px;
  grid-template-columns: 1fr 50px 1fr 50px 1fr;
`;

export const Link = styled.div`
  min-height: 80px;
  position: relative;

  &::after {
    ${({ type }) => {
      if (type === "vertical") {
        return css`
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: calc(50% + 2.5px);
          height: 100%;
          border-left: 5px solid white;
        `;
      }
      if (type === "horizontal") {
        return css`
          content: "";
          display: block;
          position: absolute;
          top: calc(50% - 2.5px);
          left: 0;
          width: 100%;
          border-top: 5px solid white;
        `;
      }
      if (type === "right-bottom") {
        return css`
          content: "";
          display: block;
          position: absolute;
          top: calc(50% - 2.5px);
          right: 0;
          width: calc(50% - 2.5px);
          height: calc(50% + 2.5px);
          border-top: 5px solid white;
          border-left: 5px solid white;
        `;
      }
      if (type === "left-bottom") {
        return css`
          content: "";
          display: block;
          position: absolute;
          top: calc(50% - 2.5px);
          left: 0;
          width: calc(50% + 7.5px);
          height: calc(50% + 2.5px);
          border-top: 5px solid white;
          border-right: 5px solid white;
        `;
      }
    }}
  }
`;

export const Card = styled.div`
  padding: 16px;
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? "center" : "flex-start")};

  h3 {
    font-size: 12px;
    font-weight: 400;
    color: #777a7e;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    color: #777a7e;
  }

  h6 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 600;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    margin-top: 16px;
  }

  ${({ gray }) =>
    gray &&
    css`
      background-color: #eaeaeb;
      border-top: 2px solid #dadbdd;
      margin-top: 16px;
    `}
`;

export const Button = styled.button`
  align-items: center;
  background-color: #75fb9f;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 8px;
  margin-top: 16px;
  padding: 8px;
  width: fit-content;
`;

export const OverTag = styled.div`
  background-color: ${({ isOver }) => (isOver ? "#fc7d84" : "#41A05F")};
  border-radius: 100px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
`;

export const GroupCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  overflow-y: auto;
`;
