import * as S from "./GeneralView.style";
import ItemView from "./ItemView/ItemView";
import IndexesSection from "./IndexesSection/IndexesSection";

export default function GeneralView({ otbData, setOtbData, futureMonths }) {
  return (
    <>
      <IndexesSection otbData={otbData} futureMonths={futureMonths} />
      <S.Wrapper>
        <div className="lista">
          {otbData.map((category) => (
            <ItemView
              categoryData={category}
              setOtbData={setOtbData}
              futureMonths={futureMonths}
            />
          ))}
        </div>
      </S.Wrapper>
    </>
  );
}
